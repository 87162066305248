import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MakeStrengthAd from "../components/makeStrengthads"
import PartnerLogoAds from "../components/partnerLogoAds"
import ServicesSlider from "../components/ServicesAds"
// import Mac from "../images/clay-macbook-pro.png"
// import Iphone from "../images/iphone.png"
// import IphoneCell from "../images/phone-call.svg"
import AdcontactForm from "../components/AdcontactForm"

const AdLanding = ({data}) => {
  const pageContent = data.allWpPage.nodes[0];
  const pageContentPage = data.allWpPage.nodes[0]?.adlanding;
  
  
  return(
    <Layout>
      <Seo title={pageContent?.seo.title} image="https://dev.alkye.com/wp-content/uploads/2021/12/Sonia-1-1-scaled-1.webp" description={pageContent?.seo.metaDesc} focuskw={pageContent?.seo.focuskw} />    
      
      <div className="page-intro page-ad pb-120 page-inner">
          <div className="position-relative">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-5">
                    <h1 className="title mb-3 mb-md-4 pb-xl-2" data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html: pageContentPage?.bannerHeading}} />
                    
                    <div className="d-flex award-list flex-wrap align-items-center" data-aos="fade-up" data-aos-delay="400">
                      {pageContentPage?.awards?.map((item, index) => (
                        <div className="award-item d-flex align-items-center" key={index}><span dangerouslySetInnerHTML={{__html: item.name}} /></div>
                      ))}
                    </div>
                    <div  data-aos="fade-up" data-aos-delay="600" dangerouslySetInnerHTML={{__html: pageContent?.content}} />
                </div>
                </div>
            </div>

            <div className="page-graphic d-flex justify-content-end">
                <div className="page-graphic-inner position-relative">
                    <img src={'/clay-macbook-pro.webp'} alt="Mac" data-aos="fade-left" data-aos-delay="200" />

                    <div className="banner-iphone" data-aos="fade-up" data-aos-delay="200">
                        <img src={'/iphone.webp'} alt="Mac" />

                        <div className="banner-iphone-form">
                            <img src={'/phone-call.svg'} alt="IphoneCell" className="mb-2" />
                            <h4>Get in touch</h4>
                            <p>We will aim to get in touch with you within 2 business hours.</p>

                            <AdcontactForm />

                            <p className="mt-3"><small>By submitting you are accepting our Terms and Conditions of data retention.</small></p>
                        </div>
                    </div>
                </div>
            </div>
          </div>

        </div>

        <ServicesSlider />
                
        <MakeStrengthAd title={pageContentPage?.makeDataYourStrengthSection?.title} desc={pageContentPage?.makeDataYourStrengthSection?.description} />

        <PartnerLogoAds />
    
    </Layout>
  )
}

export default AdLanding

export const query = graphql`{
    allWpPage(filter: {slug: {eq: "ad-landing"}}) {
      nodes {
        seo {
          title
          metaDesc
          focuskw
        }
        content
        adlanding {
          bannerHeading
          makeDataYourStrengthSection {
            title
            description
          }
          awards {
            name
          }
        }
      }
    }
  }`