import React, { Component } from 'react'
import { Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';

export default class AdcontactForm extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            phone: '',
            phoneError: '',
            success: false,
            stepCount: 1,
            fields: {},
            errors: {},
            services: [],
            loading: false
        };

        this.contactSubmit = this.contactSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
      }

      handleStep = (count) => {
        this.setState({
            stepCount: count
        });
      }

      handleServiceSelect  = (event)  => {
        var dayArr = [...this.state.services];
        const value = event.target.value
        const index = dayArr.findIndex(day => day === value);
        if(index > -1) {
            dayArr = [...dayArr.slice(0, index), ...dayArr.slice(index + 1)]
        } else {
            dayArr.push(value);
        }
        this.setState({services: dayArr});
     }

      handleRedo = () =>{
        this.setState({
            success: false,
            stepCount: 1,
            phone: '',
            services: [],
            fields:{
                user_name: '',
                email: '',
                organization: '',
                description: ''
            }
        });
      }

      contactSubmit = e => {
        e.preventDefault();
        
        if(this.validateForm()) {
            this.setState({
                loading: true
            });

            setTimeout(() => {
                this.setState({
                    success: true,
                    loading: false,

                    phone: '',
                    services: [],
                    fields:{
                        user_name: '',
                        description: '',
                        email: ''
                    }
                });

                // this.handleServiceSelect();
                console.log(window.location.href="/become-a-client-thank-you");
            }, 1000);

            var formdata = new FormData();
            formdata.append("user_name", this.state.fields.user_name);
            formdata.append("email", this.state.fields.email);
            formdata.append("phone", this.state.phone);
            formdata.append("description", this.state.fields.description);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch("https://dev.alkye.com/ad-landing/", requestOptions)
            .then(response => response.json())
            .then(result => {
                // this.props.history.push("/become-a-client-thank-you");
                // console.log(result)
                // this.setState({
                //     success: true,
                //     loading: false
                // });
            })
            .catch(error => console.log('error', error));
        }
      }

    handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })

		// console.log(e.target.value)
	}

    validateForm() {
        let phoneError = this.state.phoneError;
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;
	
		// First Name error messages
		if (!fields["user_name"]) {
		  formIsValid = false;
		  errors["user_name"] = "*Please enter your First Name.";
        //   this.setState({
        //     stepCount: 1
        //   });
		}
	
		// Email Address error messages
		// if (!fields["email"]) {
		//   formIsValid = false;
		//   errors["email"] = "*Please enter your Email Address.";
        // //   this.setState({
        // //     stepCount: 2
        // //   });
		// }
	
		// if (typeof fields["email"] !== "undefined") {
		//   var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		//   if (!pattern.test(fields["email"])) {
		// 	formIsValid = false;
		// 	errors["email"] = "*Please enter valid Email Address.";
        //     // this.setState({
        //     //     stepCount: 2
        //     // });
		//   }
		// }

        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "*Please enter your Email Address.";
          }
      
          if (typeof fields["email"] !== "undefined") {
              var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,15}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
              formIsValid = false;
              errors["email"] = "*Please enter valid Email Address.";
            }
          }
	
        if (!this.state.phone) {
			formIsValid = false;
			phoneError = "Please enter your phone number.";
		}
	
		// Password error messages
		
		
		// Confirm Password error messages
		if (!fields["description"]) {
		  formIsValid = false;
		  errors["description"] = "Please enter your message.";
		}
	

	
	
		this.setState({
            phoneError,
		    errors: errors
		});
		return formIsValid;
	}

      handleKeyDown = (e, count) => {
          if (e.key === 'Enter') {
                // if (this.validateForm()) {
                    this.setState({
                        stepCount: count
                    });
                // }

                // if(count === "success"){
                //     this.contactSubmit();
                // }
        }
      }

  render() {
    //   const pageContent = this.props.data;

    return (
        <div className='contact-ad' id="contact">
            <form onSubmit={this.contactSubmit}>
                <div className='mb-3'>
                    <input id="userName" className='form-control' onChange={this.handleChange} value={this.state.fields.user_name} name='user_name' onKeyDown={(e) => this.handleKeyDown(e, 2)} placeholder='Name' type="text" />

                    <div className={`invalid-feedback mt-1 ${!this.state.fields.user_name ? (this.state.errors.user_name ? 'd-block' : null) : null }`}>{this.state.errors.user_name}</div>
                </div>
                <div className='mb-3'>
                    <input id="email" className='form-control' onChange={this.handleChange} value={this.state.fields.email} name="email" onKeyDown={(e) => this.handleKeyDown(e, 3)} placeholder='Email Address' type="text" />

                    <div className={`invalid-feedback mt-1 ${!this.state.fields.email ? (this.state.errors.email ? 'd-block' : null) : null }`}>{this.state.errors.email}</div>
                </div>
                <div className='mb-3'>
                    <PhoneInput
                        inputExtraProps={{
                            required: true,
                            autoFocus: true
                        }}
                        country={'au'}
                        placeholder="Phone"
                        enableAreaCodeStretch
                        enableAreaCodes={true}
                        value={this.state.phone}
                        onKeyDown={(e) => this.handleKeyDown(e, 4)}
                        dropdownClass="form-shadow form-radius border-0"
                        inputClass="form-control"
                        onChange={(phone) => this.setState({ phone })}
                    />
                    <div className={`invalid-feedback mt-1 ${!this.state.phoneError ? (this.state.errors.phoneError ? 'd-block' : null) : null }`}>{this.state.errors.phoneError}</div>
                </div>
                <div className='mb-3'>
                    <input id="description" className='form-control' onChange={this.handleChange} value={this.state.fields.description} name='description' onKeyDown={(e) => this.handleKeyDown(e, 2)} placeholder='What would you like to talk about?' type="text" />

                    <div className={`invalid-feedback mt-1 ${!this.state.fields.description ? (this.state.errors.description ? 'd-block' : null) : null }`}>{this.state.errors.description}</div>
                </div>
                <button aria-label="Submit" type='submit' className='btn w-100 d-flex align-items-center justify-content-between btn-control text-uppercase'>
                    {this.state.loading ? 'Loading...' : 'Submit'} 

                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi ms-2 bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                </button>
            </form>
            {/* {this.state.success && (
                <div className='contact-form-thankyou pt-2'>
                    <p>Thank you for contacting Alkye Services. <br />We will get in touch with you soon.</p>
                </div>
            )} */}
        </div>
    )
  }
}
